import React, { Fragment } from "react";
import ShortTeaser from "../../../../../../components/ShortTeaser/index";
import PagePathUtility from "../../../../../../libs/PagePathUtility";
import ContentLibs from "../../../../../cms_content/libs/ContentLibs";
import ContentApiService from "../../../../../cms_content/services/ContentApiService";
import GeneralUtility from "../../../../../../libs/GeneralUtility";
import getConfig from "next/config";
import ArticleApiService from "../../../../../articles/services/ArticleApiService";

const EventsTeaserLayout = ({ events }) => {
  const { publicRuntimeConfig } = getConfig();

  const layoutConfig = {
    default: {
      pattern: [
        {
          sizes: {
            large: 4,
            medium: 6,
            small: 12,
          },
        },
        {
          sizes: {
            large: 8,
            medium: 6,
            small: 12,
          },
        },
        {
          sizes: {
            large: 4,
            medium: 6,
            small: 12,
          },
        },
        {
          sizes: {
            large: 4,
            medium: 6,
            small: 12,
          },
        },
        {
          sizes: {
            large: 4,
            medium: 6,
            small: 12,
          },
        },
        {
          sizes: {
            large: 8,
            medium: 6,
            small: 12,
          },
        },
        {
          sizes: {
            large: 4,
            medium: 6,
            small: 12,
          },
        },
      ],
      config: {
        repeatLast: false,
      },
    },
  };

  return (
    <div className="grid-x grid-margin-x grid-margin-y">
      {events &&
        events.map((event, index) => {
          let itemLayoutConfig = ContentLibs.getItemSizeConfigFromColumnArray(
            layoutConfig["default"],
            index
          );

          const eventDetailLink = PagePathUtility.getPathConfig(
            "ltoEventDetailFromSolr",
            { ...event }
          );

          const teaserImage = event.image_list_uid_intS;
          const employerLogo = event.employer_logo_masked_stringS;

          let bottomIcons = [];
          if (event.truecities_stringM) {
            const locationsAsString = event.truecities_stringM.reduce(
              (acc, location) => (acc ? `${acc}, ${location}` : location),
              ""
            );

            bottomIcons.push({ icon: "location", label: locationsAsString });
          }

          let bgImagePath = teaserImage
            ? ArticleApiService.getImageUrl(
                teaserImage,
                {
                  width: 450,
                },
                true
              )
            : ContentApiService.getImageUrl(
                1378961, // Bilderpool für Events Teaser (PID 1603)
                {
                  width: 450,
                  cropVariant: itemLayoutConfig.sizes.large === 8 ? 'rect' : 'tile'
                },
                true
              );

          return (
            <Fragment key={`event-teaser-${index}`}>
              <ShortTeaser
                sizeLarge={itemLayoutConfig.sizes.large}
                sizeMedium={itemLayoutConfig.sizes.medium}
                sizeSmall={itemLayoutConfig.sizes.small}
                layout={itemLayoutConfig.layout?.name}
                title={event.title}
                subtitle={new Date(
                  event.eventdate_intS * 1000
                ).toLocaleDateString("de-DE", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
                linkHref={eventDetailLink}
                bgImageSrc={bgImagePath}
                topImageSrc={employerLogo}
                topImageTextReplacement={
                  employerLogo ? "" : event.companyname_stringS
                }
                // topImageAdditionalClass={employerLogoDimensionCategory}
                bottomTags={bottomIcons ? bottomIcons : []}
              />
            </Fragment>
          );
        })}
    </div>
  );
};

export default EventsTeaserLayout;
