import { fetchEvents } from "../../services/EventApiService";
import useSWR from "swr";
import getConfig from "next/config";
import { useEffect } from "react";
import EventsTeaserLayout from "./layout/EventsTeaserLayout";
import EventsListLayout from "./layout/EventsListLayout";

const EventList = ({
  employers = [],
  itemCount = 5,
  layout = "list",
  filterTopEvents = false,
  randomItems = false,
  onResultCountChanged = null,
}) => {
  const filter = {
    employer: employers,
    onlyCareerEvents: true,
    // disabled filterTopEvents to show exact itemCount of events (top events are always on top)
    // ...(filterTopEvents ? { onlyTopEvents: filterTopEvents } : {}),
  };
  const sorting = randomItems ? "random_asc" : "";

  const { publicRuntimeConfig } = getConfig();
  const { data } = useSWR(
    "/events-" + employers.join(",") + filterTopEvents
      ? "filter-top-events"
      : "all",
    async () =>
      await fetchEvents("", filter, "", itemCount, 1, sorting)
        .then((resp) => {
          return {
            eventSearch: resp,
            dataLoaded: true,
          };
        })
        .catch(() => {
          return {
            eventSearch: null,
            dataLoaded: true,
          };
        }),
    {
      ...publicRuntimeConfig.globalSwrConfig,
      revalidateOnMount: true,
      initialData: {
        eventSearch: null,
        dataLoaded: false,
      },
    }
  );
  const { eventSearch, dataLoaded } = data;

  const items = eventSearch?.result?.solr?.response?.docs || [];

  useEffect(() => {
    onResultCountChanged && dataLoaded && onResultCountChanged(items.length);
  }, [dataLoaded]);

  if (items.length === 0) {
    return null;
  } else if (layout === "teaser") {
    return <EventsTeaserLayout events={items} />;
  } else {
    return <EventsListLayout events={items} />;
  }
};

export default EventList;
