import PagePathUtility from "../../../../../../libs/PagePathUtility";
import ItemList from "../../../../../../components/ItemList";

const EventListLayout = ({ events }) => {
  return (
    <ItemList
      items={events?.map((event) => {
        const startDate = new Date(event.eventdate_intS * 1000);
        const endDate = new Date(event.eventdateend_intS * 1000);

        let dateString = "";
        if (
          event.eventdateend_intS &&
          startDate.getMonth() !== endDate.getMonth()
        ) {
          dateString =
            startDate.toLocaleDateString("de-DE", {
              day: "numeric",
              month: "short",
            }) +
            " - " +
            endDate.toLocaleDateString("de-DE", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
        } else if (
          event.eventdateend_intS &&
          startDate.getDate() !== endDate.getDate()
        ) {
          dateString =
            startDate.toLocaleDateString("de-DE", {
              day: "numeric",
            }) +
            ". - " +
            endDate.toLocaleDateString("de-DE", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
        } else if (
          !endDate ||
          !event.eventdateend_intS ||
          startDate.getDate() === endDate.getDate()
        ) {
          dateString = startDate.toLocaleDateString("de-DE", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
        }

        return {
          id: event.id,
          title: event.title,
          subTitle: dateString,
          link: PagePathUtility.getPathConfig("ltoEventDetailFromSolr", event),
          subHeaderItems: [
            { label: event.organizername_stringS, iconName: "interface-home" },
            {
              label: event.truecities_stringM?.join(",") || "",
              iconName: "location",
            },
          ],
        };
      })}
    />
  );
};

export default EventListLayout;
