import CeWrapper from "../../global/CeWrapper";
import React, { useContext, useState } from "react";
import { PageContext } from "../../../context/PageContext";
import EventList from "../../../../events/components/EventList";
import { DefaultLayoutMappings } from "../../../libs/CeDefaults";

const EventsList = ({ ceData }) => {
  const { employer } = useContext(PageContext);
  const [hideElement, setHideElement] = useState(false);

  if (hideElement) {
    return null;
  }

  const selectedEmployerIds = ceData.contentParts?.employers?.reduce(
    (acc, employer) => {
      acc.push(employer.uid);
      return acc;
    },
    []
  );

  const employers = selectedEmployerIds || (employer ? [employer.uid] : []);

  return (
    <CeWrapper ceData={ceData} layoutMapping={DefaultLayoutMappings()}>
      <EventList
        employers={employers}
        onResultCountChanged={(itemCount) =>
          setHideElement(!itemCount || itemCount === 0)
        }
        itemCount={ceData.contentParts?.itemCount}
        layout={ceData.contentParts?.layout}
        filterTopEvents={!!parseInt(ceData.contentParts?.filterTopEvents)}
        randomItems={!!parseInt(ceData.contentParts?.random)}
      />
    </CeWrapper>
  );
};

export default EventsList;
