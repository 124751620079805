import { fetchData } from "../../../libs/Fetch";
import GeneralUtility from "../../../libs/GeneralUtility";

// const additionalParams = 'type=1598940600';
const domain = process.env.NEXT_PUBLIC_ARTICLE_API_DOMAIN;
const apiBasePath = "/restevents";

const fetch = (url, payload = {}, method = "GET") => {
  url = apiBasePath + url;
  // url += url.indexOf('?') > 0 ? '&' : '?';
  // url += additionalParams;

  return fetchData(domain + url, payload, method);
};

/**
 * Fetch articles
 *
 * @param query
 * @param filter
 * @param location
 * @param resultsPerPage
 * @param page
 * @param sort
 */
export const fetchEvents = (
  query,
  filter,
  location = "",
  resultsPerPage = 5,
  page = 1,
  sort = ""
) => {
  let url = "/search?type=1661428559";

  let requestParams = {
    query: query,
    location: location,
    resultsPerPage,
    page,
  };

  if (!GeneralUtility.isEmptyObject(filter)) {
    requestParams = {
      ...requestParams,
      filter,
    };
  }

  if (sort) {
    requestParams = {
      ...requestParams,
      sort,
    };
  }

  return fetch(url, { params: requestParams }, "POST");
};
